/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.AccountPage {
  overflow: hidden;
  padding: 2rem;
  background: #f9f6ef;
}
.AccountPage > .logout {
  width: 100%;
  text-align: right;
  padding-bottom: 3rem;
}
.AccountPage > .maxWidthContentWrapper > .message {
  width: 100%;
  margin: 0 auto;
  color: #fff;
  padding: 3rem;
  font-size: 1.5rem;
  text-align: center;
  border-radius: 1rem;
  margin-bottom: 5rem;
  font-weight: 600;
  background: #745a9e;
  border-bottom: 0.7rem solid #534468;
}
.AccountPage .panel {
  margin-top: 2rem;
  float: right;
  width: 60%;
  padding: 5rem;
  border-bottom: 0.7rem solid #e6e0d0;
  border-radius: 1rem;
  background: #fff;
}
@media (max-width: 1199px) {
  .AccountPage .panel {
    width: calc(100% - 27rem);
  }
}
@media (max-width: 767px) {
  .AccountPage .panel {
    width: 100%;
  }
}
.AccountPage .panel.panel--noBackground {
  padding: 0;
  border-bottom: 0 solid #fff;
  background: none;
  border-radius: 0;
}
.AccountPage .panel--positioning {
  float: right;
  width: 60%;
}
.AccountPage .billingLinked p,
.AccountPage .billingRestrictionWarning {
  font-size: 2rem;
  font-weight: 600;
}
.AccountPage .header {
  font-size: 4rem;
  font-weight: 600;
  margin-bottom: 2rem;
  color: #745a9e;
  border-bottom: 0.1rem solid #f9f6ef;
}
.AccountPage .AccountDetails form {
  font-size: 1.4rem;
}
.AccountPage .AccountDetails input,
.AccountPage .AccountDetails select {
  border: 0;
  font-size: 1.4rem;
}
